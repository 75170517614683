export default class CandidateTableModel {
  constructor(candidate) {
    this.id = candidate.id;
    this.firstName = candidate.first_name;
    this.email = candidate.email;
    this.lastName = candidate.last_name;
    this.fullName = `${candidate.last_name} ${candidate.first_name}`;
    this.gender = candidate.gender;
    this.birthdate = candidate.date_of_birth;
    this.date_joined = candidate.date_joined;
    this.status = ''; // candidate.status;
    this.testingStatus = candidate.testing_status ?? '';
    this.reliability = candidate.tests_results && candidate.tests_results.general_info ? candidate.tests_results.general_info.reliability : '';
    this.test_date = candidate.tests_results && candidate.tests_results.general_info ? candidate.tests_results.general_info.test_date : '';
    this.manager = candidate.manager ? `${candidate.manager.last_name} ${candidate.manager.first_name}` : '';
    this.company = candidate.manager && candidate.manager.customer ? candidate.manager.customer.company : '';
    this.position = candidate.position ? candidate.position : '';
    this.position_level = candidate.position_level ? candidate.position_level : '';
    this.education = candidate.education ? candidate.education : '';
    this.tests = candidate.tests_results && candidate.tests_results.general_info && candidate.tests_results.general_info.tests ? candidate.tests_results.general_info.tests : [];

    if (candidate.tests_results) {
      this.testTime = candidate?.tests_results?.general_info?.time ?? '';
      this.degreePositionCompliance = '';
      this.riskFactors = candidate.tests_results.risk_factors?.factors ?? null;
      this.attentionFactor = candidate.tests_results.attantion_factor?.factors ?? null;

      this.scales = candidate.tests_results.scales?.scales?.scales ?? null;
      this.peak_scales = candidate.tests_results.scales?.peak_scales?.scales ?? null;
      if (this.peak_scales) {
        this.peak_scales.sort((a, b) => b.value - a.value);
        this.peak_scales = this.peak_scales.map(el => `${el.title_start}/${el.title_end}`);
      }
      this.competences = candidate.tests_results.personal_abilities;

      this.teamRoles = candidate.tests_results.team_roles?.team_roles?.team_roles ?? null;
      this.teamRolesN = candidate.tests_results.team_roles_normalized?.team_roles?.team_roles ?? null;
      this.teamRolesSorted = this.teamRoles ? [...this.teamRoles] : [];
      this.teamRolesSorted.sort((a, b) => b.points - a.points);
      this.teamRolesSorted = this.teamRolesSorted.map(el => el.title);

      this.intellectualAbility = null;
      this.profInterests = candidate.tests_results.prof_interests?.prof_interests?.prof_interests ?? null;
      this.profInterestsSorted = this.profInterests ? [...this.profInterests] : [];
      this.profInterestsSorted.sort((a, b) => b.points - a.points);
      this.profInterestsSorted = this.profInterestsSorted.map(el => el.title);

      this.logic = candidate.tests_results.logic?.results ?? null;

      this.str_stress_tolerance = candidate.tests_results.stress_tolerance?.stress_tolerance_name;
      this.str_leadership_styles = candidate.tests_results.leadership_styles?.HIGHT?.leadership_styles?.map(p => p.title).join(', \n\r');

      this.str_anxiety = candidate.tests_results.anxiety?.anxiety_level;

      this.str_competences_1 = candidate.tests_results.personal_abilities?.competences?.filter(p => p.level > 7 && p.level <= 10).map(p => p.title).join(', \n\r');
      this.str_competences_2 = candidate.tests_results.personal_abilities?.competences?.filter(p => p.level > 3 && p.level < 8).map(p => p.title).join(', \n\r');
      this.str_competences_3 = candidate.tests_results.personal_abilities?.competences?.filter(p => p.level >= 0 && p.level < 4).map(p => p.title).join(', \n\r');

      this.str_motivators = candidate.tests_results.motivators_and_destructors?.motivators?.motivators?.map(p => p.title).join(', \n\r');
      this.motivators = candidate.tests_results.motivators_and_destructors?.motivators?.motivators?.slice(0, 3).map(p => p.title);
      // console.log(this.motivators);
      this.str_destructors = candidate.tests_results.motivators_and_destructors?.destructors?.destructors?.map(p => p.title).join(', \n\r');
      this.destructors = candidate.tests_results.motivators_and_destructors?.destructors?.destructors?.slice(0, 3).map(p => p.title);

      this.compliances = candidate.compliances ? candidate.compliances.sort((a, b) => b.compliance - a.compliance) : [];
      this.str_compliance = candidate.compliances.map(el => `${el.professiogram_name} - ${Math.round(el.compliance * 100)}`).join('. ');

      this.management = candidate.tests_results.management_potential?.potentials;
    }

    this.comment = candidate.comment ?? '';

    this.constructor_test_results = candidate.tests_results && candidate.tests_results.constructor_test_results ? candidate.tests_results.constructor_test_results : [];
    // console.log('this.constructor_test_results', this.constructor_test_results);
  }
}
