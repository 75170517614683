/* eslint-disable */

import CandidateTableModel from '@/common/models/CandidateTableModel';
import * as XLSX from 'xlsx';
import { HEADERS, INTELLECTUAL_ABILITY, PROF_INTERESTS, SCALES, getStrReliability} from '@/common/constants/candidateTableHeaders';
import { TESTING_STATUSES_TITLES } from '@/common/constants/testingStatuses';
import { GENDERS } from '@/common/constants/genders';
import * as ColumnsSetup from '@/common/exportExcel/columnsCandidats';
import { loadColumnsSetup } from '@/common/exportExcel/ExcelColumnsStorage';
import moment from 'moment';

const NOT_DATA_TEXT = '-';

export default class CandidatesToTable {
  constructor(candidates) {
    this.candidates = candidates;
    this.toTable();
  }

  static getGender(gender) {
    if (gender === GENDERS.MALE) return 'М';
    if (gender === GENDERS.FEMALE) return 'Ж';
    return NOT_DATA_TEXT;
  }

  static getBirthdate(birthdate) {
    return birthdate ? moment(birthdate).format('YYYY') : NOT_DATA_TEXT;
  }

  static getDate(birthdate) {
    const dt = moment(birthdate)
    return dt && dt.isValid() ? dt.format('DD.MM.YYYY') : NOT_DATA_TEXT;
  }

  static getAge(birthdate) {
    const dt = moment(birthdate)
    return dt && dt.isValid() ? (new Date()).getFullYear() - (+dt.format('YYYY')) : '-'
  }

  static getTestTime(candidate, testCode) {
    const test = candidate.tests.find(t => t.test_code === testCode);
    return test ? test.time : NOT_DATA_TEXT
  }

  static sortScales(scales) {
    if (scales) {
      const tmp = [];
      Object.values(SCALES).forEach((title) => {
        if (title==='Женские/Мужские черты в характере') {
          const item1 = scales.find((el) => el.scale === 'Mf-m');
          const item2 = scales.find((el) => el.scale === 'Mm');

          tmp.push(item1 && item1.value ? item1.value : (item2 ? item2.value : NOT_DATA_TEXT));
          return
        }

        const item = scales.find((el) => (`${el.title_start}/${el.title_end}`) === title);
        tmp.push(item ? item.value : NOT_DATA_TEXT);
      });
      return tmp;
    }
    return new Array(13).fill(NOT_DATA_TEXT);
  }

  static sortProfInterests(profInterests) {
    if (profInterests) {
      const tmp = [];
      Object.values(PROF_INTERESTS).forEach((title) => {
        const item = profInterests.find((el) => el.title === title);
        tmp.push(item ? item.points : NOT_DATA_TEXT);
      });
      return tmp;
    }
    return new Array(12).fill(NOT_DATA_TEXT);
  }

  static sortLogic(logic) {
    if (logic) {
      const tmp = [];
      Object.values(INTELLECTUAL_ABILITY).forEach((title) => {
        const item = logic.find((el) => el.title === title);
        tmp.push(item ? item.value : NOT_DATA_TEXT);
      });
      return tmp;
    }
    return new Array(3).fill(NOT_DATA_TEXT);
  }

  static sortMostourism(titles, candidate, testCode) {
      const tmp = [];
      const arr = candidate.constructor_test_results[testCode] ? candidate.constructor_test_results[testCode].blocks : []
      titles.forEach((title) => {
        const item = arr.find((el) => el.block.title === title);
        tmp.push(item ? item.value : NOT_DATA_TEXT);
      });
      return tmp;
  }


  toTable() {
    const columnsSetup = loadColumnsSetup(ColumnsSetup.columnsKey, ColumnsSetup.columns)

    function addCol(arr, key, value) {
      if (key===true || columnsSetup[key]) {
        if (Array.isArray(value)) {
          arr.push(...value);
          return value.length
        }
        else {
          arr.push(value);
          return 1
        }
      }
      return 0
    }


    const arrRoles = [];
    const arrRolesN = [];
    this.candidates.forEach(c => {
      const teamRoles = c.tests_results?.team_roles?.team_roles?.team_roles ?? null;
      const teamRolesN = c.tests_results?.team_roles_normalized?.team_roles?.team_roles ?? null;

      if (teamRoles) {
        teamRoles.forEach(r => {
          if (!arrRoles.includes(r.title))
            arrRoles.push(r.title);
        });
      }
      if (teamRolesN) {
        teamRolesN.forEach(r => {
          if (!arrRolesN.includes(r.title))
            arrRolesN.push(r.title);
        });
      }
    });

    arrRoles.sort();
    arrRolesN.sort();

    const arrManagementTitles = [];
    this.candidates.forEach(c => {
      const management = c.tests_results?.management_potential?.potentials
      if (management) {
        management.forEach(r => {
          if (!arrManagementTitles.includes(r.title))
            arrManagementTitles.push(r.title);
        });
      }
    });

    const arrConsructorData = [];
    this.candidates.forEach(c => {
      
      const arrTestCodes = c.tests_results.constructor_test_results ? Object.keys(c.tests_results.constructor_test_results) : []
      arrTestCodes.forEach(testCode => {
          const checkKey = 'CONSTRUCTOR_REPORT_' + testCode
          if (columnsSetup[checkKey]) {
            const titles = c.tests_results.constructor_test_results[testCode].blocks.map(el => el.block?.title)
            const title = c.tests_results.constructor_test_results[testCode].name
            if (!arrConsructorData.find(el=>el.testCode === testCode))
              arrConsructorData.push({ testCode, title, titles });
            }
      });
    })

    const headersRow1 = []
    let blokPersonal = 0
    blokPersonal += addCol(headersRow1, 'FIO', HEADERS.FIO)
    blokPersonal += addCol(headersRow1, 'EMAIL', HEADERS.EMAIL)
    blokPersonal += addCol(headersRow1, 'GENDER', HEADERS.GENDER)
    blokPersonal += addCol(headersRow1, 'AGE', HEADERS.AGE)
    blokPersonal += addCol(headersRow1, 'POSITION', HEADERS.POSITION)
    blokPersonal += addCol(headersRow1, 'POSITION_LEVEL', HEADERS.POSITION_LEVEL)
    blokPersonal += addCol(headersRow1, 'EDUCATION', HEADERS.EDUCATION)

    let blokCommon = 0
    blokCommon += addCol(headersRow1, 'TEST_TIME', HEADERS.TEST_TIME)
    blokCommon += addCol(headersRow1, 'TESTING_STATUS', HEADERS.TESTING_STATUS)
    blokCommon += addCol(headersRow1, 'COMPANY', HEADERS.COMPANY)
    blokCommon += addCol(headersRow1, 'TEST_TIME_MMPI', HEADERS.TEST_TIME_MMPI)  // Время прохождения личностного опросника
    blokCommon += addCol(headersRow1, 'TEST_TIME_PROF_INTERESTS', HEADERS.TEST_TIME_PROF_INTERESTS)  // Время прохождения
    blokCommon += addCol(headersRow1, 'TEST_TIME_NUMERIC_LOGIC', HEADERS.TEST_TIME_NUMERIC_LOGIC)  // Время прохождения
    blokCommon += addCol(headersRow1, 'TEST_TIME_VERBAL_LOGIC', HEADERS.TEST_TIME_VERBAL_LOGIC)  // Время прохождения
    blokCommon += addCol(headersRow1, 'TEST_TIME_NON_VERBAL_LOGIC', HEADERS.TEST_TIME_NON_VERBAL_LOGIC)  // Время прохождения
    blokCommon += addCol(headersRow1, 'MANAGER', HEADERS.MANAGER)
    blokCommon += addCol(headersRow1, 'COMMENT',  HEADERS.COMMENT)

    let blokMain = 0
    blokMain += addCol(headersRow1, 'RELIABILITY', HEADERS.RELIABILITY)
    if (columnsSetup['BEHAVIOUR']) {
      addCol(headersRow1, true, HEADERS.BEHAVIOUR_1)
      addCol(headersRow1, true, HEADERS.BEHAVIOUR_2)
      addCol(headersRow1, true, HEADERS.BEHAVIOUR_3)
      blokMain += 3
    }
    blokMain += addCol(headersRow1, 'ATTENTION_FACTOR', HEADERS.ATTENTION_FACTOR)
    blokMain += addCol(headersRow1, 'RISK_FACTORS', HEADERS.RISK_FACTORS)
    blokMain += addCol(headersRow1, 'MANAGEMENT_STYLE', HEADERS.MANAGEMENT_STYLE)
    blokMain += addCol(headersRow1, 'STRESS_TOLERANCE', HEADERS.STRESS_TOLERANCE)

    blokMain += addCol(headersRow1, 'ANXIETY', HEADERS.ANXIETY) // Тревожность
    // Другое

    let blokScales = 0
    if (columnsSetup['SCALES']) {
      addCol(headersRow1, true, SCALES['0'] + ' (L)')
      addCol(headersRow1, true, SCALES['1'] + ' (F)')
      addCol(headersRow1, true, SCALES['2'] + ' (K)')
      addCol(headersRow1, true, SCALES['3'] + ' (Hs)')
      addCol(headersRow1, true, SCALES['4'] + ' (D)')
      addCol(headersRow1, true, SCALES['5'] + ' (Hy)')
      addCol(headersRow1, true, SCALES['6'] + ' (Pd)')
      addCol(headersRow1, true, SCALES['7'] + ' (Mf-m)')
      addCol(headersRow1, true, SCALES['8'] + ' (Pa)')
      addCol(headersRow1, true, SCALES['9'] + ' (Pt)')
      addCol(headersRow1, true, SCALES['10'] + ' (Sc)')
      addCol(headersRow1, true, SCALES['11'] + ' (Ma)')
      addCol(headersRow1, true, SCALES['12'] + ' (Si)')
      blokScales += 13
    }

    let blokManagement = addCol(headersRow1, 'MANAGEMENT_POTENTIAL', arrManagementTitles) // Управленческий потенциал

    let blokRoles = addCol(headersRow1, 'TEAM_ROLES', arrRoles)
    if (columnsSetup['TEAM_ROLES']) {
      addCol(headersRow1, 'TEAM_ROLES', HEADERS.TEAM_ROLES_FIRST_1)
      addCol(headersRow1, 'TEAM_ROLES', HEADERS.TEAM_ROLES_FIRST_2)
      addCol(headersRow1, 'TEAM_ROLES', HEADERS.TEAM_ROLES_FIRST_3)
      addCol(headersRow1, 'TEAM_ROLES', HEADERS.TEAM_ROLES_LAST)
      blokRoles+=4
    }

    let blokRolesN = 0 // addCol(headersRow1, 'TEAM_ROLES_NORMALIZED', arrRolesN.map(title => `${title} (норм.)`))

    let blokIntellect = 0
    if (columnsSetup['INTELLECTUAL_ABILITY']) {
      addCol(headersRow1, true, INTELLECTUAL_ABILITY['0'])
      addCol(headersRow1, true, INTELLECTUAL_ABILITY['1'])
      addCol(headersRow1, true, INTELLECTUAL_ABILITY['2'])
      blokIntellect += 3
    }

    let blokProfInterests = 0
    if (columnsSetup['PROF_INTERESTS']) {
      addCol(headersRow1, true, PROF_INTERESTS['0'])
      addCol(headersRow1, true, PROF_INTERESTS['1'])
      addCol(headersRow1, true, PROF_INTERESTS['2'])
      addCol(headersRow1, true, PROF_INTERESTS['3'])
      addCol(headersRow1, true, PROF_INTERESTS['4'])
      addCol(headersRow1, true, PROF_INTERESTS['5'])
      addCol(headersRow1, true, PROF_INTERESTS['6'])
      addCol(headersRow1, true, PROF_INTERESTS['7'])
      addCol(headersRow1, true, PROF_INTERESTS['8'])
      addCol(headersRow1, true, PROF_INTERESTS['9'])
      addCol(headersRow1, true, PROF_INTERESTS['10'])
      addCol(headersRow1, true, PROF_INTERESTS['11'])
      blokProfInterests += 12

      addCol(headersRow1, true, HEADERS.PROF_INTERESTS_1)
      addCol(headersRow1, true, HEADERS.PROF_INTERESTS_2)
      addCol(headersRow1, true, HEADERS.PROF_INTERESTS_3)
      blokProfInterests += 3
    }

    // let blokProfnavigatorResults = addCol(headersRow1, 'PROFNAVIGATOR_RESULTS', arrMostourismTitles)

    let blokMotivators = 0
    if (columnsSetup['MOTIVATORS']) {
      addCol(headersRow1, true, HEADERS.MOTIVATORS)
      addCol(headersRow1, true, HEADERS.MOTIVATOR_1)
      addCol(headersRow1, true, HEADERS.MOTIVATOR_2)
      addCol(headersRow1, true, HEADERS.MOTIVATOR_3)
      blokMotivators += 4
    }

    let blokDestructors = 0
    if (columnsSetup['DESTRUCTORS']) {
      addCol(headersRow1, true, HEADERS.DESTRUCTORS)
      addCol(headersRow1, true, HEADERS.DESTRUCTOR_1)
      addCol(headersRow1, true, HEADERS.DESTRUCTOR_2)
      addCol(headersRow1, true, HEADERS.DESTRUCTOR_3)
      blokDestructors += 4
    }

    let blokcompetences = 0
    if (columnsSetup['COMPETENCES']) {
      blokcompetences = 3
      addCol(headersRow1, true, HEADERS.COMPETENCES_1)
      addCol(headersRow1, true, HEADERS.COMPETENCES_2)
      addCol(headersRow1, true, HEADERS.COMPETENCES_3)
    }
    let blokCompliance = 0
    if (columnsSetup['COMPLIANCE']) {
      blokCompliance = 1
      addCol(headersRow1, true, "Список должностей")
    }

    arrConsructorData.forEach(el=>{
      addCol(headersRow1, true, el.titles)
    })

    const rows = this.candidates.map((c) => {
      const candidate = new CandidateTableModel(c);

      let riskFactors = NOT_DATA_TEXT;
      if (candidate.riskFactors) {
        riskFactors = candidate.riskFactors?.map((f) => f.title).join(';\n');
      }
      let attentionFactor = NOT_DATA_TEXT;
      if (candidate.attentionFactor) {
        attentionFactor = candidate.attentionFactor?.map((f) => f.title).join(';\n');
      }

      // const teamRolesText = candidate.teamRoles
      //   ? candidate.teamRoles.map((r) => `${r.title} - ${r.points}`).join(';\n')
      //   : NOT_DATA_TEXT;

      const roles = [];
      arrRoles.forEach(titleRole => {
        const role = candidate.teamRoles?.find(r => r.title === titleRole);
        roles.push(role ? role.points : 0);
      });

      const rolesN = [];
      arrRolesN.forEach(titleRole => {
        const role = candidate.teamRolesN?.find(r => r.title === titleRole);
        rolesN.push(role ? role.points : 0);
      });

      const management = [];
      arrManagementTitles.forEach(title => {
        const m = candidate.management?.find(r => r.title === title);
        let str = '-'
        if (m?.level>7) str = 'Высокий'
        else if (m?.level>3) str = 'Средний'
        else if (m?.level>=0) str = 'Низкий'
        management.push(str);
      });

      const row = []
      addCol(row, 'FIO', candidate.fullName || NOT_DATA_TEXT)
      addCol(row, 'EMAIL', candidate.email || NOT_DATA_TEXT)
      addCol(row, 'GENDER', CandidatesToTable.getGender(candidate.gender))
      addCol(row, 'AGE', CandidatesToTable.getAge(candidate.birthdate))
      addCol(row, 'POSITION', candidate.position)
      addCol(row, 'POSITION_LEVEL', candidate.position_level)
      addCol(row, 'EDUCATION', candidate.education)

      addCol(row, 'TEST_TIME', CandidatesToTable.getDate(candidate.test_date))
      addCol(row, 'TESTING_STATUS', TESTING_STATUSES_TITLES[candidate.testingStatus] ?? NOT_DATA_TEXT)
      addCol(row, 'COMPANY', candidate.company ||  NOT_DATA_TEXT)
      addCol(row, 'TEST_TIME_MMPI', CandidatesToTable.getTestTime(candidate, 'MMPI'))  // 'Время прохождения личностного опросника',
      addCol(row, 'TEST_TIME_PROF_INTERESTS', CandidatesToTable.getTestTime(candidate, 'PROF_INTERESTS'))  // Время прохождения
      addCol(row, 'TEST_TIME_NUMERIC_LOGIC', CandidatesToTable.getTestTime(candidate, 'NUMERIC_LOGIC'))  // Время прохождения
      addCol(row, 'TEST_TIME_VERBAL_LOGIC', CandidatesToTable.getTestTime(candidate, 'VERBAL_LOGIC'))  // Время прохождения
      addCol(row, 'TEST_TIME_NON_VERBAL_LOGIC', CandidatesToTable.getTestTime(candidate, 'NON_VERBAL_LOGIC'))  // Время прохождения
      addCol(row, 'MANAGER', candidate.manager)
      addCol(row, 'COMMENT', candidate.comment || NOT_DATA_TEXT)

      addCol(row, 'RELIABILITY', getStrReliability(candidate.reliability))
      if (columnsSetup['BEHAVIOUR']) {
        addCol(row, true, candidate.peak_scales && candidate.peak_scales[0] ? candidate.peak_scales[0] : NOT_DATA_TEXT )
        addCol(row, true, candidate.peak_scales && candidate.peak_scales[1] ? candidate.peak_scales[1] : NOT_DATA_TEXT )
        addCol(row, true, candidate.peak_scales && candidate.peak_scales[2] ? candidate.peak_scales[2] :  NOT_DATA_TEXT )
      }

      addCol(row, 'ATTENTION_FACTOR', attentionFactor)
      addCol(row, 'RISK_FACTORS', riskFactors)
      addCol(row, 'MANAGEMENT_STYLE', candidate.str_leadership_styles)
      addCol(row, 'STRESS_TOLERANCE', candidate.str_stress_tolerance)
      addCol(row, 'ANXIETY', candidate.str_anxiety) // Тревожность
      // Другое
      addCol(row, 'SCALES', CandidatesToTable.sortScales(candidate.scales))

      addCol(row, 'MANAGEMENT_POTENTIAL', management) // Управленческий потенциал

      addCol(row, 'TEAM_ROLES', roles)
      addCol(row, 'TEAM_ROLES', candidate.teamRolesSorted[0] || NOT_DATA_TEXT)
      addCol(row, 'TEAM_ROLES', candidate.teamRolesSorted[1] || NOT_DATA_TEXT)
      addCol(row, 'TEAM_ROLES', candidate.teamRolesSorted[2] || NOT_DATA_TEXT)
      addCol(row, 'TEAM_ROLES', candidate.teamRolesSorted[candidate.teamRolesSorted.length-1] || NOT_DATA_TEXT)

      // addCol(row, 'TEAM_ROLES_NORMALIZED', rolesN)

      addCol(row, 'INTELLECTUAL_ABILITY', CandidatesToTable.sortLogic(candidate.logic))

      addCol(row, 'PROF_INTERESTS', CandidatesToTable.sortProfInterests(candidate.profInterests))
      addCol(row, 'PROF_INTERESTS', candidate.profInterestsSorted[0] || NOT_DATA_TEXT )
      addCol(row, 'PROF_INTERESTS', candidate.profInterestsSorted[1] || NOT_DATA_TEXT )
      addCol(row, 'PROF_INTERESTS', candidate.profInterestsSorted[2] || NOT_DATA_TEXT )

      // addCol(row, 'PROFNAVIGATOR_RESULTS', CandidatesToTable.sortMostourism(arrMostourismTitles, candidate))

      addCol(row, 'MOTIVATORS', candidate.str_motivators)
      addCol(row, 'MOTIVATORS', candidate.motivators && candidate.motivators[0] ? candidate.motivators[0] : NOT_DATA_TEXT)
      addCol(row, 'MOTIVATORS', candidate.motivators && candidate.motivators[1] ? candidate.motivators[1] : NOT_DATA_TEXT)
      addCol(row, 'MOTIVATORS', candidate.motivators && candidate.motivators[2] ? candidate.motivators[2] : NOT_DATA_TEXT)

      addCol(row, 'DESTRUCTORS', candidate.str_destructors)
      addCol(row, 'DESTRUCTORS', candidate.destructors && candidate.destructors[0] ? candidate.destructors[0] : NOT_DATA_TEXT)
      addCol(row, 'DESTRUCTORS', candidate.destructors && candidate.destructors[1] ? candidate.destructors[1] : NOT_DATA_TEXT)
      addCol(row, 'DESTRUCTORS', candidate.destructors && candidate.destructors[2] ? candidate.destructors[2] : NOT_DATA_TEXT)

      if (columnsSetup['COMPETENCES']) {
        addCol(row, true, candidate.str_competences_1)
        addCol(row, true, candidate.str_competences_2)
        addCol(row, true, candidate.str_competences_3)
      }

      if (columnsSetup['COMPLIANCE']) {
        addCol(row, true, candidate.str_compliance)
      }

      arrConsructorData.forEach(el=>{
        addCol(row, true, CandidatesToTable.sortMostourism(el.titles, candidate, el.testCode))
      })

      return row;
    });





    let headersRow0 = [];
    let arrMerge = []
    let curCol = 0
    function addTopCol(cnt, title) {
      if (!cnt)
        return
      headersRow0.push(title)
      if (cnt > 1) {
        arrMerge.push({
          s: { r: 0, c: curCol },
          e: { r: 0, c: curCol + cnt - 1 }
        })
        headersRow0 = headersRow0.concat(Array(cnt-1).fill(''))
      }
      curCol += cnt;
    }

    addTopCol(blokPersonal, 'Личные данные')
    addTopCol(blokCommon, 'Общие данные')
    addTopCol(blokMain, 'Основные данные')
    addTopCol(blokScales, 'Шкалы')
    addTopCol(blokManagement, 'Управленческий потенциал')
    addTopCol(blokRoles, 'Роли в команде')
    addTopCol(blokRolesN, 'Роли в команде (нормированные)')
    addTopCol(blokIntellect, 'Структура интеллекта')
    addTopCol(blokProfInterests, 'Профессиональные интересы')
    // addTopCol(blokProfnavigatorResults, 'Результаты профнавигатора')
    addTopCol(blokMotivators, '')
    addTopCol(blokDestructors, '')
    addTopCol(blokcompetences, 'Личностные способности')
    addTopCol(blokCompliance, HEADERS.COMPLIANCE)

    arrConsructorData.forEach(el=>{
      addTopCol(el.titles.length, el.title)
    })


    const ws = XLSX.utils.aoa_to_sheet([
      headersRow0,
      headersRow1,
      ...rows,
    ]);

    // ws['!cols'] = [
    //   { wch: Math.max(HEADERS.FIO.length, 30) },
    //   { wch: Math.max(HEADERS.GENDER.length, 20) },
    //   { wch: Math.max(HEADERS.BIRTHDATE.length, 20) },
    // ];
    ws['!merges'] = arrMerge;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Кандидаты');

    XLSX.writeFile(wb, 'Кандидаты.xlsx');

    return this.candidates;
  }
}
